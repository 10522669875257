






































import { Vue, Component } from "vue-property-decorator";

@Component
export default class Works extends Vue {
  currentPage = 1;

  mounted() {
    this.$store.dispatch("loadCategories");
    this.$store.dispatch("loadWorks", this.currentPage);
  }

  get works() {
    return this.$store.state.works;
  }

  get categories() {
    return this.$store.state.categories;
  }

  get totalPages() {
    return this.$store.state.worksTotalPages;
  }

  getCategoriesName(ids: Array<string | number>) {
    const names: string[] = [];
    ids.forEach((id: string | number) => {
      if (this.categories.length > 0) {
        const cat = this.categories.find(
          (c: { id: number }) => c.id === parseInt(id as string)
        );
        if (cat && cat.name) names.push(cat.name);
      }
    });
    return names;
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.$store.dispatch("loadWorks", this.currentPage);
  }
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
    this.$store.dispatch("loadWorks", this.currentPage);
  }
}

/*
const mockPost = {
  id: 466,
  date: "2019-01-31T01:04:54",
  date_gmt: "2019-01-31T00:04:54",
  guid: {
    rendered: "https://www.fransstudio.de/?p=466",
  },
  modified: "2019-01-31T01:04:54",
  modified_gmt: "2019-01-31T00:04:54",
  slug: "webpack-power",
  status: "publish",
  type: "post",
  link: "https://www.fransstudio.de/uncategorized/webpack-power/",
  title: {
    rendered: "Webpack power",
  },
  content: {
    rendered:
      "<p>I love webpack, ent.</p> \n <p>Well, without going into deep to that philosophical programming question, I love it because at the end of the day, I always want to use a single file Javascript and CSS most of the time. I want to use cutting-edge technology most of the time. I want to use cool imports exports, Typescript, Promises, and new plugins everyday. And dealing with polyfills, managing versions of it, is always a mess. Yarn/Npm helps of course, compiling and managing it from one point and auto-updating it helps making development fun and fast again.</p> \n <p>Now you can concentrate on what is important: Logical, Analytical, Code Structuring, and of course spends the next hour searching the name for a variable you will change again in a month, now that the purpose of the variable changed or you just understant it better now.</p> \n <p>Set up webpack.config.js, follow most tutorials, set a base.js or a centralized js file to import it in order, and it will understand SASS ordering, mixins, and it could also do dynamic loading of Styles depended on components or inclusion in the javascript file.</p> \n <p>I love it.</p> \n",
    protected: false,
  },
  excerpt: {
    rendered:
      " <p>I love webpack, it is a powerful open-sourced tool for front-end developer like me. I notice that every plugin I use, every other tools I use, like framework, new programming language, etc, the principial endpoint and way-of-doing is the same. And maybe what is more important is of course, the user experience at the end [&hellip;]</p> \n",
    protected: false,
  },
  author: 1,
  featured_media: 0,
  comment_status: "open",
  ping_status: "open",
  sticky: false,
  template: "",
  format: "standard",
  meta: [],
  categories: [1],
  tags: [],
  _links: {
    self: [{ href: "https://www.fransstudio.de/wp-json/wp/v2/posts/466" }],
    collection: [{ href: "https://www.fransstudio.de/wp-json/wp/v2/posts" }],
    about: [{ href: "https://www.fransstudio.de/wp-json/wp/v2/types/post" }],
    author: [
      {
        embeddable: true,
        href: "https://www.fransstudio.de/wp-json/wp/v2/users/1",
      },
    ],
    replies: [
      {
        embeddable: true,
        href: "https://www.fransstudio.de/wp-json/wp/v2/comments?post=466",
      },
    ],
    "version-history": [
      {
        count: 1,
        href: "https://www.fransstudio.de/wp-json/wp/v2/posts/466/revisions",
      },
    ],
    "predecessor-version": [
      {
        id: 467,
        href:
          "https://www.fransstudio.de/wp-json/wp/v2/posts/466/revisions/467",
      },
    ],
    "wp:attachment": [
      { href: "https://www.fransstudio.de/wp-json/wp/v2/media?parent=466" },
    ],
    "wp:term": [
      {
        taxonomy: "category",
        embeddable: true,
        href: "https://www.fransstudio.de/wp-json/wp/v2/categories?post=466",
      },
      {
        taxonomy: "post_tag",
        embeddable: true,
        href: "https://www.fransstudio.de/wp-json/wp/v2/tags?post=466",
      },
    ],
    curies: [{ name: "wp", href: "https://api.w.org/{rel}", templated: true }],
  },
};
*/
